import Vue from 'vue'
import VueRouter from 'vue-router'

import NewPublic from '@/layout/NewPublic'
import Auth from '@/layout/NewAuth'
import Dashboard from '@/layout/NewDashboard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'home',
    component: NewPublic,
    children: [
      {
        path: '/',
        name: 'home',
        component: () =>
          import('@/views/Home.vue'),
      },
      {
        path: '/about',
        name: 'About',
        component: () =>
          import('@/views/About.vue'),
      },
      {
        path: '/scan',
        name: 'Scan',
        component: () =>
          import('@/views/Scan.vue'),
      },
      {
        path: '/check/:id',
        name: 'Check',
        component: () =>
          import('@/views/Check.vue'),
      },
      {
        path: '/update-pending-to-paid',
        name: 'payment-success',
        component: () =>
            import('@/views/Payment/PaymentSuccess.vue'),
        props: true
      },
      {
        path: '/payment/failed/:id',
        name: 'payment-failed',
        component: () =>
            import('@/views/Payment/PaymentFailed.vue'),
        props: true
      },
      {
        path: '/:useraff/:link',
        name: 'LandingAff',
        component: () =>
          import('@/views/LAffiliate.vue'),
      },
      {
        path: '/privacy-policy',
        name: 'Privacy Policy',
        component: () =>
          import('@/views/PrivacyPolicy.vue'),
      },
    ],
  },
  {
    path: '/login',
    redirect: 'login',
    component: Auth,
    children: [
      {
        path: '/login',
        name: 'login',
        component: () =>
          import('@/views/Auth/NewLogin.vue'),
      },
      {
        path: '/register',
        name: 'Register',
        component: () =>
          import('@/views/Auth/NewRegister.vue'),
      },
      {
        path: '/forgot',
        name: 'Forgot',
        component: () =>
          import('@/views/Auth/NewForgot.vue'),
      },
      {
        path: '/reset-password',
        name: 'Reset',
        component: () =>
          import('@/views/Auth/NewResetPassword.vue'),
      },
    ],
    meta: { onAuth: true },
  },
  {
    path: '/dashboard',
    redirect: 'dashboard',
    component: Dashboard,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () =>
          import('@/views/Dashboard/Home.vue'),
      },
      {
        path: '/library/:id?',
        name: 'library',
        component: () =>
          import('@/views/Dashboard/NewLibrary.vue'),
      },
      {
        path: '/publish',
        name: 'publish',
        component: () =>
          import('@/views/Dashboard/Terbit.vue'),
      },
      {
        path: '/naskah/update/:id',
        name: 'update',
        component: () =>
          import('@/views/Dashboard/Update.vue'),
        meta: { requiresAuth: true, role: "admin" },
      },
      {
        path: '/profile',
        name: 'profile',
        component: () =>
          import('@/views/Dashboard/Profile.vue'),
      },
      {
        path: '/affiliate/:id?',
        name: 'affiliate',
        component: () => 
            import('@/views/Dashboard/Affiliate.vue'),
      },
      {
        path: '/product',
        name: 'product',
        component: () =>
            import('@/views/Dashboard/Reseller.vue')
      }
    ],
    meta: { requiresAuth: true },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
